import React from "react";
import ReactDOM from 'react-dom/client';
import { Link, useLocation } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

export default function Footer( { toggleOffcanvas, offcanvasState } ) {
    const location = useLocation();
    // const isActive = (path) => location.pathname === path || (path !== '/' && location.pathname.startsWith(path));
    const isActive = (path) => location.pathname === path;

    return (
        <>
            <footer className="site-footer py-2  border-top d-flex align-items-center justify-content-center container">
                <Link to="/" aria-label="Hirejunaid" className="text-decoration-none text-capitalize text-dark fw-normal fs-4">HireJunaid</Link>
            </footer>

            {/* Offcanvas menu */}
            <article className={`offcanvas-menu bg-white vh-100 p-4 position-fixed top-0 ${offcanvasState ? 'offcanvas-menu--active' : ''}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <Link
                        to="/"
                        aria-label="Hirejunaid"
                        className="text-decoration-none text-dark text-center d-block
                        fs-1 fw-bold">HJ</Link>
                    <IoMdClose className="fs-3" onClick={toggleOffcanvas} />
                </div>
                
                <hr />

                <nav className="footer--nav px-2  pt-3 transition-3">
                    <ul id="offcanvas-menu" className="list-unstyled m-0 p-0">
                        <li className={`d-flex justify-content-center mb-2 menu-item ${isActive('/') ? 'bg-dark' : ''}`}>
                            <Link
                                to="/"
                                aria-label="Home"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                text-center ${isActive('/') ? 'text-white' : 'text-dark'}`}>Home</Link>
                        </li>
                        <li className={`d-flex justify-content-center mb-2 menu-item ${isActive('/about') ? 'bg-dark' : ''}`}>
                            <Link
                                to="/about"
                                aria-label="About"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/about') ? 'text-white' : 'text-dark'}`}>About</Link>
                        </li>
                        <li className={`d-flex justify-content-center mb-2 menu-item ${isActive('/portfolio') ? 'bg-dark' : ''}`}>
                            <Link
                                to="/portfolio"
                                aria-label="Portfolio"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/portfolio') ? 'text-white' : 'text-dark'}`}>Portfolio</Link>
                        </li>
                        <li className={`d-flex justify-content-center menu-item ${isActive('/contact') ? 'bg-dark' : ''}`}>
                            <Link
                                to="/contact"
                                aria-label="Contact"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/contact') ? 'text-white' : 'text-dark'}`}>Contact</Link>
                        </li>
                    </ul>
                </nav>

                <hr />
                <div className="d-flex justify-content-center mt-4">
                    <Link to="https://wa.me/923402460806" target="_blank"
                        className="hire-now outline-0 py-2 px-4 rounded-px font-urbanist fw-semibold bg-vivid-blue
                        text-white text-decoration-none"
                        aria-label="Hire Now" style={{border: '1px solid white'}}>Hire Now &#10230;</Link>
                </div>

            </article>

        </>
    )
}