import React, { useState } from "react";
import ReactDOM from 'react-dom/client';
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

export default function Layout() {
    const [isActive, setIsActive] = useState(false);

    const toggleOffcanvas = () => {
        setIsActive((isActive) => !isActive);
    }

    return (
        <>
            <Header toggleOffcanvas={toggleOffcanvas} />
            <main id="main-content" className="main-content">
                <Outlet />
            </main>
            <Footer toggleOffcanvas={toggleOffcanvas} offcanvasState={isActive} />
        </>
    )
}