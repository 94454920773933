import React from "react";
import ReactDOM from 'react-dom/client';
import { Link, useLocation } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";

export default function Header( {toggleOffcanvas} ) {
    const location = useLocation();
    // const isActive = (path) => location.pathname === path || (path !== '/' && location.pathname.startsWith(path));
    const isActive = (path) => location.pathname === path;

    return (
        <header className="header p-2">
            <div className="container py-1 px-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="site-logo font-urbanist">
                        <Link
                        to="/"
                        aria-label="Hirejunaid"
                        className="text-decoration-none text-dark">HJ</Link>
                    </div>
                    <nav className="header--nav px-2 py-3 bg-dark transition-3 d-none d-lg-block">
                        <ul id="header-menu" className="list-unstyled m-0 p-0">
                            <li className="d-inline-block menu-item">
                                <Link
                                to="/"
                                aria-label="Home"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/') ? 'menu--active' : ''}`}>Home</Link>
                            </li>
                            <li className="d-inline-block menu-item">
                                <Link
                                to="/about"
                                aria-label="About"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/about') ? 'menu--active' : ''}`}>About</Link>
                            </li>
                            <li className="d-inline-block menu-item">
                                <Link
                                to="/portfolio"
                                aria-label="Portfolio"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/portfolio') ? 'menu--active' : ''}`}>Portfolio</Link>    
                            </li>
                            <li className="d-inline-block menu-item">
                                <Link
                                to="/contact"
                                aria-label="Contact"
                                className={`text-decoration-none text-light py-2 px-4 text-uppercase fw-bold menu-link
                                ${isActive('/contact') ? 'menu--active' : ''}`}>Contact</Link>    
                            </li>
                        </ul>
                    </nav>

                    <Link to="https://wa.me/923402460806" target="_blank"
                    className="hire-now outline-0 py-2 px-4 rounded-px font-urbanist fw-semibold bg-vivid-blue
                    text-white text-decoration-none d-none d-lg-block"
                    aria-label="Hire Now" style={{border: '1px solid white'}}>Hire Now &#10230;</Link>

                    <FaBars className="fs-3 d-lg-none" onClick={toggleOffcanvas}/>

                </div>
            </div>
        </header>
    )
}