import React from "react";
import ReactDOM from 'react-dom/client';
import { useEffect } from "react";
import Banner from "../templates/banner";
import dva from "../assets/images/dva.webp";
import forteagency from "../assets/images/forteagency.webp";
import ips from "../assets/images/ips.webp";
import dmc from "../assets/images/DMC.webp";
import WhalenFurniture from "../assets/images/whalen-furniture.webp";
import Automatelife from "../assets/images/automatelife.webp";

export default function Portfolio() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <>
            <Banner title={'Portfolio - HireJunaid'} />
            <div className="container my-5">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${dva}`} alt="Durango Value Autos"
                            title="Durango Value Autos" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">Durango Value Autos</figcaption>
                        </figure>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${dmc}`} alt="Durango Motor Company"
                            title="Durango Motor Company" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">Durango Motor Company</figcaption>
                        </figure>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${forteagency}`} alt="The Forte Agency"
                            title="The Forte Agency" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">The Forte Agency</figcaption>
                        </figure>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${ips}`} alt="IP wise solutions"
                            title="IP wise solutions" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">IP wise solutions</figcaption>
                        </figure>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${WhalenFurniture}`} alt="Whalen Furniture"
                            title="Whalen Furniture" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">Whalen Furniture</figcaption>
                        </figure>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <figure>
                            <img src={`${Automatelife}`} alt="Automate Life"
                            title="Automate Life" width="500" height="231" loading="lazy"
                            className="object-fit-contain w-100 border shadow-sm" />
                            <figcaption className="text-center mt-1">Automate Life</figcaption>
                        </figure>
                    </div>

                </div>
            </div>
        </>
    )
}